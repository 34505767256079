import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface SliceState {
    verification: string | null
}

const initialState: SliceState = {
    verification: null,
};

export const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        fetchVerification: (state, action: PayloadAction<string>) => {
            state.verification = action.payload;
        }
    }
});

export const selectVerification = (state: RootState) => state.app.verification;
export const {
    fetchVerification,
} = slice.actions;

export default slice.reducer;