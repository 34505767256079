import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Service from '../components/Service';
import AppointmentButton from '../components/AppointmentButton';
import { adolescentApproaches, adolescents, adultApproaches, adults, childParent, childParentApproaches, children, ServiceID } from '../util/Services';
import { useRef } from 'react';

const Services: React.FC = () => {
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const infantSection = useRef<HTMLDivElement>(null);
  const childrenSection = useRef<HTMLDivElement>(null);
  const adolescentSection = useRef<HTMLDivElement>(null);
  const adultSection = useRef<HTMLDivElement>(null);
  return (
    <Grid container width={"100%"} height={'fit-content'} alignItems="center" justifyContent={'center'} padding={1}>
      <Grid item container width={"100%"} alignItems="center" justifyContent={'center'} padding={isMdOrLess ? 1 : 3} maxWidth={'lg'} >
        <Grid item>
          <Typography align={'center'} sx={{ fontFamily: 'Lora, serif'}} variant={'h4'}>
            Services
          </Typography>
        </Grid>
        {!isSmOrLess && (
          <Grid item className={"right"}>
            <AppointmentButton />
          </Grid>
        )}
      </Grid>
      <Grid container width={"100%"} alignItems="center" justifyContent={'center'} maxWidth={'lg'} >
        <Grid item xs={12} ref={infantSection}>
          <Service
            title={'Infant/Young Child & Parent'}
            description={'description'}
            listItems={childParent}
            approachesTitle={'Therapeutic Approaches'}
            approaches={childParentApproaches}
            image={''}
            imageLeft={true}
            id={ServiceID.infant}
          />
        </Grid>
        <Grid item xs={12} ref={adolescentSection}>
          <Service
            title={'Adolescents'}
            description={'description'}
            listItems={adolescents}
            approachesTitle={'Therapeutic Approaches'}
            approaches={adolescentApproaches}
            image={''}
            id={ServiceID.adolescent}
          />
        </Grid>
        <Grid item xs={12} ref={adultSection}>
          <Service
            title={'Adults'}
            listItems={adults}
            description={'description'}
            approachesTitle={'Therapeutic Approaches'}
            approaches={adultApproaches}
            image={''}
            imageLeft={true}
            id={ServiceID.adult}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Services;
