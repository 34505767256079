import * as React from 'react';
import { Link, Typography, Grid, TextField, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AppointmentButton from '../components/AppointmentButton';
import { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import emailjs from '@emailjs/browser';

const Contact: React.FC = () => {
  const service = 'service_emmgpy8';
  const template = 'template_1lroxbb';
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [nameFilled, setNameFilled] = useState<boolean>(true);
  const [emailFilled, setEmailFilled] = useState<boolean>(true);
  const [messageFilled, setMessageFilled] = useState<boolean>(true);
  const [submitAllowed, setSubmitAllowed] = useState<boolean>(false);
  const [sendAttemps, setSendAttemps] = useState<number>(0);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [sendFail, setSendFail] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  useEffect(() => {
      setSubmitAllowed(!!(name && email && message));
      if (name != undefined && name != '') {
        setNameFilled(true);
      }
      if (email != undefined && email != '') {
        setEmailFilled(true);
      } 
      if (message != undefined && message != '') {
        setMessageFilled(true);
      }
  }, [name, email, message]);
  useEffect(() => {
    if (sendSuccess) {
      // Show success
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setTimeout(() => {
        setMessageFilled(true);
        setEmailFilled(true);
        setNameFilled(true);
        setSendSuccess(false);
      },4000);
    } else if (sendFail) {
      // Show fail send.
      setTimeout(() => {
        setSendFail(false);
      },4000);
    }
  }, [sendSuccess, sendFail]);
  const sendMessage = () => {
    if (submitAllowed) {
      // Send email.
      setSending(true);
      setSendAttemps(sendAttemps + 1);
      emailjs.send(service, template, {
        from_name: name,
        reply_to: email,
        phone: phone,
        message: message,
      }).then(() => {
        setSendSuccess(true);
        setSending(false);
      }, () => {
        setSendFail(true);
        setSending(false);
      });
    } else {
      if (!name || name == '') {
        setNameFilled(false);
      }
      if (!email || email == '' || !EmailValidator.validate(email)) {
        setEmailFilled(false);
      }
      if (!message || message == '') {
        setMessageFilled(false);
      }
    }
  };
  return (
    <React.Fragment>
      <Grid container justifyContent={'center'} padding={4} >
        <Grid container justifyContent={'center'} maxWidth={900}>
          <Grid item container sm={12} md={6} padding={2} spacing={2} alignContent={'start'}>
            <Grid item>
              <Typography align={'left'} variant={'h5'} sx={{ fontFamily: 'Lora, serif'}}>
                Contact Me
              </Typography>
            </Grid>
            <Grid item padding={2}>
              <Typography align={'justify'} variant={'body2'}>
                Reaching out to a counselor can be incredibly hard- but also worthwhile. I welcome anyone who is looking for support to reach out by email or phone to see if we are a good fit. I also would love to hear from parents seeking therapy for their children.
              </Typography>
            </Grid>
            <Grid item width={'100%'}>
              <AppointmentButton />
            </Grid>
          </Grid>
          <Grid item container sm={12} md={6} minHeight={400} padding={2} spacing={1.5}>
            <Grid item xs={12}>
              <TextField id="outlined-basic" color={nameFilled ? undefined : 'warning'} focused={!nameFilled} helperText={nameFilled ? '' : 'Please provide your name'} value={name} onChange={props => setName(props.target.value)} fullWidth label="Name" required variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" color={emailFilled ? undefined : 'warning'} focused={!emailFilled} helperText={emailFilled ? '' : 'Please provide a valid email'} value={email} type={'email'} onChange={props => setEmail(props.target.value)} fullWidth label="Email" required variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" value={phone} type={'tel'} onChange={props => setPhone(props.target.value)} fullWidth label="Phone" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" color={messageFilled ? undefined : 'warning'} focused={!messageFilled} helperText={messageFilled ? '' : 'Please provide a message'} value={message} onChange={props => setMessage(props.target.value)} fullWidth label="Message" required multiline rows={7} variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton loading={sending} fullWidth onClick={sendMessage} variant={'contained'}>{sending ? 'Sending' : 'Send'}</LoadingButton>
            </Grid>
            <Grid item xs={11}>
              {sendFail && <Alert severity="warning">There was an issue sending your message, please try again.</Alert>}
              {sendSuccess && <Alert severity="success">Thank you for your message. I will be in touch within 2-3 business days.</Alert>}
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Typography align={'justify'} variant={'caption'}>
              Angela Contento, LMHC does not provide emergency crisis services. If you or someone you know is experiencing a safety or mental health emergency, please call <Link target="_blank" underline={'hover'} href={'tel:911'}>911</Link> or mobile crisis at <Link target="_blank" underline={'hover'} href={'tel:+15855293721'}>(585) 529-3721</Link>. Please do not use this contact form or leave a voicemail in a time of crisis, as my phone and email are not monitored 24 hours a day.
              National Suicide Prevention Hotline: <Link target="_blank" underline={'hover'} href={'tel:+18002738255'}>1-800-273-8255</Link>
            </Typography>
          </Grid> 
        </Grid> 
      </Grid>
    </React.Fragment>
  );
}

export default Contact;
