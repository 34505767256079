import * as React from 'react';
import Info from '../components/Info';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Hike from '../media/hike.jpg';

const Home: React.FC = () => {
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container justifyContent={'center'} flexDirection={isMdOrLess ? 'row-reverse' : 'row'}>
      <Grid item height={'300px'} width={"100%"}>
        <Box
          sx={{
            height: '-webkit-fill-available',
            backgroundImage: `url(${Hike})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            border: 'none',
            // borderBottomLeftRadius: 80,
            // borderBottomRightRadius: 80,
            //borderRadius: 40,
            //maskImage: 'linear-gradient(180deg, rgba(240,255,243,1) 70%, rgba(240,255,243,.5) 90%, rgba(255,255,255,0) 100%)',
            zIndex: -2
          }}
        />
      </Grid>
      <Info />
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={Hike}
        alt="increase priority"
      />
    </Grid>
  );
}

export default Home;
