import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    spwidgetWrap: {
        textAlign: 'center',
    },
    spwidgetButton: {
        display: 'inline-block',
        padding: '5px 15px',
        color: '#fff !important',
        background: '#78866b',
        border: 0,
        lineHeight: "1.65",
        fontSize: '16px',
        borderRadius: 80,
        fontWeight: 400,
        textDecoration: 'none',
        ':hover': {
            background: '#d15913',
        },
        ':active': {
            color: 'rgba(255, 255, 255, .75) !important',
            boxShadow: '0 1px 3px rgba(0, 0, 0, .15) inset',
        }
    }
  })
);
const AppointmentButton: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.spwidgetWrap}>
            <a href="https://julie-saraceno.clientsecure.me" className={classes.spwidgetButton}>
                Request Appointment
            </a>
        </div>
    );
}

export default AppointmentButton;
