import * as React from 'react';
import { Grid, Typography, Card, useMediaQuery, useTheme } from '@mui/material';
import Ac from '../media/ac_headshot.jpeg';
import AppointmentButton from '../components/AppointmentButton';

const About: React.FC= () => {
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container minWidth={360} justifyContent={'center'} alignContent={'center'} padding={2} paddingBottom={8}>
        <Grid item justifyContent={'left'} >
            <Card elevation={5} 
              sx={{
                height: 260,
                minWidth: 260,
                backgroundImage: `url(${Ac})`,
                backgroundPosition: 'top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: -2,
                borderRadius: 60,
              }}
            />
            <Grid item marginTop={3}>
                <Typography align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={'h5'}>Angela Contento</Typography>
                <Typography align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={'h6'}>LCSW, NCC</Typography>
                <Typography letterSpacing={1} align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={'subtitle2'}>Licensed Clinical Social Worker</Typography>
                <Typography letterSpacing={1} align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={'subtitle2'}>LCSW license # </Typography>
                <Grid item marginTop={3} justifySelf={'left'}>
                  <AppointmentButton />
                </Grid>
            </Grid>
        </Grid>
        {/* Increase the network loading priority of the background image. */}
        <img
            style={{ display: 'none' }}
            src={Ac}
            alt="increase priority"
        />
        <Grid item justifyContent={'left'} maxWidth={600} padding={2}>
          <Typography align={'left'} variant={'h4'} sx={{ fontFamily: 'Lora, serif', fontWeight: 600 }} padding={1}>
            About me
          </Typography>
          <Typography align={'left'} variant={'body2'} padding={2}>
            text
            {/* <i><b style={{ fontFamily: 'Lora, serif', fontSize: 20 }}>Welcome</b></i>
            {', I am honored you are here! As a Clinical Social Worker and Registered Play Therapist, I am here to partner with you on your journey to restoring balance and mental wellness to your life.'}  */}
          </Typography>
          <Typography align={'left'} variant={'body2'} padding={2}>
            {/* I have over seven years of clinical experience providing individual and family therapy to those impacted by trauma, grief/loss, relational ruptures, parenting struggles, anxiety, and perinatal/postpartum wellness. A majority of my experiences and expertise comes from the numerous years I spent as a Senior Clinician for a local non-profit before transitioning to opening my own practice.  */}
          </Typography>
          <Typography align={'left'} variant={'body2'} padding={2}>
            {/* Therapeutic services are available to indivdiuals ranging from birth through adulthood. For my youngest clients, birth to 5 years old, I offer Infant/Child-Parent Psychotherapy (or Infant Mental Health), which is a therapeutic model that focuses on the relationship between the infant and their caregiver. For kiddos ranging from 5 to 12, I specialize in offering Child Centered Play Therapy. Then for adolescents and adults, I offer a combination of traditional talk therapy and art informed intervention. For all clients, I offer a wide range of coping and mindfulness techniques. */}
          </Typography>
        </Grid>
    </Grid>
  );
}

export default About;
