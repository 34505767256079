import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import AppProvider from './components/AppProvider';
import emailjs from '@emailjs/browser';

// TODO: New email js for Angela
emailjs.init('');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
    <AppProvider>
        <App />
    </AppProvider>
);
