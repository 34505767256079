import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';


export const createModeTheme = (mode: string) => {
  return createTheme({
    palette: {
      mode: mode as PaletteMode,
    },
  });
};
