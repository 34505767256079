export const acceptedInsurance: string[] = [
    'Aetna',
    'Blue Cross/BS of Massachusetts',
    'Cigna',
    'Horizon BC/BS of New Jersey',
    'Oscar Health',
    'Oxford',
    'UnitedHealthcare UHC | UBH'
];

export interface Fee {
  text: string,
  note: string
}
export const privatePay: Fee[] = [
    {
        text: 'Individual Sessions $130',
        note: ''
    }
];
