import * as React from 'react';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import { Grid, Typography, useMediaQuery, useTheme, Card, CardMedia, Link, Divider } from '@mui/material';
import Ac from '../media/ac_headshot.jpeg';
import AppointmentButton from './AppointmentButton';

const Info: React.FC = () => {
    const theme = useTheme();
    const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid item container maxWidth={'1000px'} minHeight={'300px'} justifyContent={'center'} padding={'24px 24px 16px 48px'}>
            <Grid item container justifyContent={isMdOrLess ? 'center' : 'space-between'} maxWidth={'720px'}>
                <Grid item container width={'auto'}>
                    <Card component={Grid} minHeight={'260px'} maxWidth={"260px"} maxHeight={"260px"} width={"300px"} elevation={5} sx={{ borderRadius: 40 }} marginTop={isMdOrLess ? '-80px' : '0px'}> 
                        <CardMedia sx={{ width: "100%", height: "100%", backgroundPosition: 'top' }} image={Ac} />
                    </Card>
                </Grid>
                <Grid item container width={isMdOrLess ? '100%' : '405px'} alignContent={'space-between'} padding={isSmOrLess ? '16px 0px 0px 0px' : '16px 16px 0px 16px'}>
                    <Grid item width={'100%'} justifyContent={'center'}>
                        <Typography align={isMdOrLess ? 'center' : 'left'} fontWeight={300} variant={isSmOrLess ? 'h4' : 'h3'} sx={{ fontFamily: 'Lora, serif'}}>Angela Contento</Typography>
                        <Typography align={isMdOrLess ? 'center' : 'left'} fontWeight={300} variant={isSmOrLess ? 'h5' : 'h4'} sx={{ fontFamily: 'Lora, serif'}}>LMHC, NCC</Typography>
                        <Typography align={isMdOrLess ? 'center' : 'left'} fontWeight={400} variant={'subtitle1'} sx={{ fontFamily: 'Lora, serif'}}>Licensed Mental Health Counselor</Typography>
                    </Grid>
                    <Grid item container flexDirection={'row'} alignContent={'center'} justifyContent={'space-between'} paddingTop={'24px'}>
                        <Grid item alignSelf={'center'}>
                            <Grid item width={'auto'}>
                                <Typography color={'#333333'} align={'left'} fontWeight={400} variant={'subtitle2'}>Let's connect!</Typography>
                            </Grid>
                            <Grid item container>
                            <Link target="_blank" underline={'hover'} color={'#779ba1'} href={'tel:+15853718370'}><PhonelinkRingIcon sx={{ color: '#779ba1'}} /></Link>
                                <Typography align={'left'} variant={'body2'} marginTop={'4px'} paddingLeft={'8px'}>
                                    <Link target="_blank" underline={'hover'} color={'#779ba1'} href={'tel:+15853718370'}>(585) 371-8370</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item alignSelf={'end'} marginBottom={'12px'}>
                            <AppointmentButton />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container width={'100%'} style={{ width: '100%' }} justifyContent={'center'}>
                <Grid item justifyContent={'center'} padding={'16px 0px'} xs={12} marginTop={'auto'}>
                    <Divider variant='fullWidth' sx={{ width: '100%' }}/>
                </Grid>
                <Typography letterSpacing={0.5} padding={'0px 0px'} align={'justify'} variant={'body2'}>
                    I'm here to support clients who are looking to strengthen their mental health, relationships, and adaptability. Every day we face challenges and stress; whether it is neurobiologically based, interpersonal, a trauma response, or the result of systemic stressors of an unjust society. While I use a variety of approaches and techniques based on client needs, my foundation is person centered. You deserve a safe, nonjudgmental space to break down or build back up. I believe my clients have all the answers within them and are capable of great things. My role is to use my education, training, and empathy to help you reach your goals.
                </Typography>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'justify'} variant={'body2'}>
                    I am a licensed mental health counselor with 10 years of combined training & experience. I have worked with child & adult survivors of domestic and sexual violence at an advocacy agency, with families and adolescents diagnosed with substance use disorders in a residential treatment facility, and adults online/in person at an integrated healthcare setting.
                </Typography>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'justify'} variant={'body2'}>
                    Reaching out to a counselor can be incredibly hard- but also worthwhile. I welcome anyone who is looking for support to email or call me to touch base and see if we are a good fit. We can also schedule a quick phone or video call if you would like. I also would love to hear from parents seeking therapy for their children.
                </Typography>
            </Grid>
            <Grid item container width={'100%'} style={{ width: '100%' }} justifyContent={'center'} md={4}>
                <Typography letterSpacing={0.5} padding={'16px 0px'} align={'left'} variant={'body2'}>
                    
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Info;
