
export const enum ServiceEnum {
    infant=1,
    children=2,
    adolescent=3,
    adult=4
};

export const enum ServiceID {
    infant='infant-section',
    children='children-section',
    adolescent='adolescent-section',
    adult='adult-section'
};

export const childParent: string[] = [
];

export const childParentApproaches: string[] = [
];

export const children: string[] = [
];

export const adolescents: string[] = [
];

export const adolescentApproaches: string[] = [
];

export const adults: string[] = [
];

export const adultApproaches: string[] = [
];