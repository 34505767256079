import * as React from 'react';
import { Typography, Grid, Box, useMediaQuery, Theme, Paper } from '@mui/material';


interface ServiceTypeProps {
    description: string,
    listItems: string[],
    title: string;
    approachesTitle: string;
    approaches: string[];
    image: string;
    id: string;
    imageLeft?: boolean;
}
  
const Service: React.FC<ServiceTypeProps> = (props: ServiceTypeProps) => {
    const { approaches, approachesTitle, description, listItems, title, image, imageLeft, id } = props;
    const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const getService = () => {
        return (
            <Grid item sm={12} md={6} padding={2}>
                <Typography variant={'h6'} padding={1}>
                    {title}
                </Typography>
                <Typography variant={'body2'} padding={1}>
                    {description}
                </Typography>
                <ul>
                    {listItems.map((text: string, index: number) => {
                        return (<li key={index}>
                            {text}
                        </li>);
                    })}
                </ul>
                <Typography variant={'h6'} padding={1}>
                    {approachesTitle}
                </Typography>
                <ul>
                    {approaches.map((text: string, index: number) => {
                        return (<li key={index}>
                            {text}
                        </li>);
                    })}
                </ul>
            </Grid>
        );
    };
    return (
        <Paper elevation={isMedium ? 2 : 0} style={{ borderRadius: 20, overflow: 'hidden', margin: isMedium ? 12 : 0 }} id={id}>
            {/* Increase the network loading priority of the background image. */}
            <img
                style={{ display: 'none' }}
                src={image}
                alt="increase priority"
            />
            <Grid container>
                {(!isMedium && imageLeft) && getService()}
                <Grid item sm={12} md={6} style={{ width: '100%' }} alignContent={'stretch'} justifyContent={'stretch'}>
                    <Grid item style={{ height: '100%', width: '100%' }}>
                        <Box sx={{
                            height: isMedium ? 360 : '100%',
                            minWidth: 360,
                            width: '100%',
                            backgroundImage: `url(${image})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            zIndex: -2,
                        }}/>
                    </Grid>
                </Grid>
                {(isMedium || !imageLeft) && getService()}
            </Grid>
        </Paper>
    );
}

export default Service;
